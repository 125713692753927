<template>
  <v-app>
    <v-main>
      <menu-dashboard/>
      <div style="margin-top: 80px; margin-bottom: 80px">
        <router-view />
      </div>

      <footer-base />
    </v-main>
  </v-app>
</template>

<script>
import FooterBase from "@/footer/base";
import MenuDashboard from "@/menu/dashboard";

export default {
  name: "layoutMain",

  components: {
    MenuDashboard,
    FooterBase
  }
};
</script>
