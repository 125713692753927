<template>
  <div :style="menuBaseStyle" class="">
    <div class="d-flex justify-center">
      <div class="d-inline-flex px-6 cursor-pointer">
        <img src="../assets/Eigen.png" height="60px" alt="eigen">
      </div>
      <div class="d-inline-flex px-6 cursor-pointer">
        <img src="../assets/Wijs.png" height="60px" alt="Wijs">
      </div>
      <div class="d-inline-flex px-6 cursor-pointer">
        <img src="../assets/Ontwikkelen.png" height="60px" alt="Ontwikkelen">
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "FooterBase",
  data() {
    return {
      menuBaseStyle: {
        'background-color': this.$vuetify.theme.themes.light.white,
        'height': '80px',
        'padding-top': '10px',
        'position': 'fixed',
        'bottom': '5px',
        'width': '100%',
        'z-index': '1000',
      },
      menuBaseLogoStyle: {
        'height': '40px'
      }
    }
  }
};
</script>
